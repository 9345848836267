/* * {
    box-sizing: border-box;
}

body {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
} */

html,
body {
	margin: 0;
	padding: 0;
	position: relative;
}

body.modal-open {
	overflow: hidden; /* Prevent scrolling on the body */
}

.pointer {
	cursor: pointer;
}

.close-button {
	z-index: 1001;
	cursor: pointer;
}

.form-header {
	z-index: 100;
}

.sticky-component {
	position: -webkit-sticky;
	position: sticky;
	top: 20px;
}

.speaker-img {
	height: 207px;
}

.location-img svg {
	width: 2.5rem;
	height: 3rem;
	padding: 0 15px 0 0;
}

.event-details-link {
	width: 33.33% !important;
}

.event-card {
	width: 100% !important;
	height: 100% !important;
}

.event-swiper {
	height: 100% !important;
}

.event-swiper .swiper-button-prev,
.event-swiper .swiper-button-next {
	display: none !important;
}

.background-image {
	width: 100% !important;
	height: 100% !important;
	/* max-height: 500px !important; */
	text-align: center !important;
	max-height: 600px !important;
	/* background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-color: #000000 !important; */
	/* background-image: url('./assets/img/photos/about5.jpg'); */
	/* filter: blur(50px) brightness(.9); */
	/* opacity: 0.5 !important; */
}

.background-image img {
	width: 100% !important;
	height: 100% !important;
	filter: blur(0px) brightness(1) !important;
	z-index: 20;
	max-height: 550px !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.object-fit-cover {
	object-fit: cover;
}

.form-parent {
	max-height: 100vh !important;
	overflow-y: scroll;
}

.modal-background {
	background-color: #39364fcc;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1050;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	top: 0;
	left: 0;
	/* overflow-y: scroll; */
}

.place-holder input::placeholder {
	color: #888 !important;
	opacity: 0.7;
}

.place-holder::placeholder::after {
	content: '*'; /* Add the asterisk */
	color: red; /* Apply your desired style to the asterisk, e.g., color, font-size, etc. */
	margin-left: 2px; /* Adjust spacing between the text and asterisk if needed */
}

@media screen and (max-width: 760px) {
	.modal-background {
		justify-content: flex-start;
	}

	.mobile-height {
		height: 100vh !important;
		border-radius: 0 !important;
	}

	.mobile-input-group {
		flex-direction: column;
	}
}

@media screen and (min-width: 768px) {
	.mobile-height {
		height: fit-content !important;
	}
	.background-image {
		height: 500px !important;
	}

	.mobile-input-group {
		flex-direction: row;
	}

	.mobile-input-group input {
		width: 45%;
	}
	/* 
    .place-holder input {
        width: 45%;
    } */
}

@media screen and (min-width: 1200px) {
	.form-parent {
		overflow-y: hidden;
	}
}

@media screen and (min-width: 1336px) {
	.background-image {
		height: 700px !important;
	}
}

