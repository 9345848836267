// Theme Colors
$sky: #5eb9f0 !default;
$cdv: #5760FF !default;
$cdv-alt: #FF9124 !default;
$blue: #3f78e0 !default;
$grape: #605dba !default;
$purple: #747ed1 !default;
$violet: #a07cc5 !default;
$pink: #d16b86 !default;
$fuchsia: #e668b3 !default;
$red: #e2626b !default;
$orange: #f78b77 !default;
$yellow: #fab758 !default;
$green: #45c4a0 !default;
$leaf: #7cb798 !default;
$aqua: #54a8c7 !default;
$navy: #343f52 !default;
$ash: #9499a3 !default;
